<div class="modal-header">
    <div class="modal-title">{{'Confirm'|translate}}</div>
</div>
<div class="modal-body">
    <div class="form-group">
        <div>
            <span>{{'Password'|translate}}</span>
        </div>
        <input class="form-control" name="secret" [(ngModel)]="secretModel" type="password" />
    </div>
</div>
<div class="modal-footer">
    <button (click)="confirm()" id="confirm" [disabled]="!secretModel" class="btn btn-primary">{{'Confirm'|translate}}</button>
    <button (click)="dismiss()" id="close" class="btn btn-light">{{'Close'|translate}}</button>
</div>